<template>
  <div class="Integration">
    <div class="Integration-banner">
      <img :src="require('@imgs/people/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/people/banner.png')" v-else>
      <div class="Integration-banner-title">
        <h3>专家介绍</h3>
        <!-- <span>NTRODUCTION EXPERTS</span> -->
      </div>
    </div>
    <div class="Integration-breadcrumb">
      当前位置:<span @click="$router.push({path: '/'})">首页</span> -> <span @click="$router.push({path: '/people'})">专家介绍</span>-> <span>详情</span>
    </div>
    <div class="Integration-content row">
      <div class="Integration-content-left col-sm-3 col-12">
        <img :src="info.expertPic" />
        <span @click="$router.back()">返回列表</span>
      </div>
      <div class="Integration-content-right col-sm-9 col-12">
        <span>{{info.expertIntroduction}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IntegrationDetail',
  data () {
    return {
      info: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$http({
        url: `expert/${this.$route.query.id}`,
      }).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>
<style scoped lang="scss">
.Integration {
  position: relative;
  &-banner {
    width: 100%;
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;
      h3 {
        font-size:20px;
        font-weight: 400;
        color: #ffffff;
        line-height: .8;
      }
      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    margin-bottom: 40px;
    display: flex;
    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80%;
        max-width: 250px;
      }
      span {
        background: #0060af;
        padding: 7px 10px;
        color: #fff;
        font-size: 14px;
        margin-top: 50px;
      }
    }
    &-right {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
    }
  }
  &-header {
    margin-top: 40px;
    align-items: center;
  }
  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
  }
}
.Integration-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
</style>